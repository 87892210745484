import { Input, ViewContainerRef, Component, ViewChild, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { WidgetTypes } from '../../domain-models/enum/widget-types';
import { NumericWidgetComponent } from '../widget-list/numeric-widget/numeric-widget.component';
import { WidgetComponentInterface } from '../widget-list/widget-component.interface';
import { DashBoardLayoutOrchestratorViewModel } from '../../view-models/dash-board-layout.orchestrator-view-model';
import { DashBoardItemViewModel } from '../../view-models/dash-board-item.view-model';
import { OnOffWidgetComponent } from '../widget-list/onoff-widget/onoff-widget.component';
import { DonutWidgetComponent } from '../widget-list/donut-widget/donut-widget.component';
import { AlertWidgetComponent } from '../widget-list/alert-widget/alert-widget.component';
import { GaugeWidgetComponent } from '../widget-list/gauge-widget/gauge-widget.component';
import { EmptyGaugeWidgetComponent } from '../widget-list/empty-gauge-widget/empty-gauge-widget.component';
import { ProgramLinkWidgetComponent } from '../widget-list/program-link-widget/program-link-widget.component';
import { ResultSetWidgetComponent } from '../widget-list/result-set-widget/result-set-widget.component';
import { BarWidgetComponent } from '../widget-list/bar-widget/bar-widget.component';
import { TimeLineWidgetComponent } from '../widget-list/time-line-widget/time-line-widget.component';
import { DynamicTimeLineWidgetComponent } from '../widget-list/dynamic-time-line-widget/dynamic-time-line-widget.component';
import { IconResultSetWidgetComponent } from '../widget-list/icon-result-set-widget-new/icon-result-set-widget.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilledButtonComponent, ModalService, NTSTranslatePipe, ServiceResponse, TextButtonComponent } from '@nts/std';
import { ZoomResultWidgetComponent } from '../widget-list/zoom-result-widget/zoom-result-widget.component';
import { MenuItemShortcutWidgetComponent } from '../widget-list/menu-item-shortcut-widget/menu-item-shortcut-widget.component';
import { IdentityShortcutWidgetComponent } from '../widget-list/identity-shortcut-widget/identity-shortcut-widget.component';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon'
import { ColorPickerModule } from 'ngx-color-picker';

@UntilDestroy()
@Component({
    selector: 'nts-widget-host',
    templateUrl: './widget-host.component.html',
    styleUrls: ['./widget-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        SvgIconComponent,
        AsyncPipe,
        ColorPickerModule,
        TextButtonComponent,
        FilledButtonComponent,
        NTSTranslatePipe,
        DatePipe
    ]
})
export class WidgetHostComponent implements OnInit {

    @Input() vm: DashBoardItemViewModel;
    @Input() orchestratorViewModel: DashBoardLayoutOrchestratorViewModel;

    @ViewChild('widgetPlaceHolder', { read: ViewContainerRef, static: true })

    widgetPlaceHolder: ViewContainerRef;
    widgetInstance: WidgetComponentInterface;
    colorPicker: string = null;
    initColorPicker = null;
    showColorPicker = false;
    componentLoaded = false;

    errorResponse: ServiceResponse;

    disableConfirm = false;
    saveColorPicker = false;

    constructor(
        private cd: ChangeDetectorRef,
        private modalService: ModalService
    ) {

    }

    public loading = true;
    public mouseIsOverWidget = false;
    public componentsMapping = {
        [WidgetTypes.OnOff]: OnOffWidgetComponent,
        [WidgetTypes.Alert]: AlertWidgetComponent,
        [WidgetTypes.Number]: NumericWidgetComponent,
        [WidgetTypes.ProgramLink]: ProgramLinkWidgetComponent,
        [WidgetTypes.Gauge]: GaugeWidgetComponent,
        [WidgetTypes.EmptyGauge]: EmptyGaugeWidgetComponent,
        [WidgetTypes.ResultSet]: ResultSetWidgetComponent,
        [WidgetTypes.TimeLine]: TimeLineWidgetComponent,
        [WidgetTypes.Bar]: BarWidgetComponent,
        [WidgetTypes.Donut]: DonutWidgetComponent,
        [WidgetTypes.DynamicTimeline]: DynamicTimeLineWidgetComponent,
        [WidgetTypes.IconResultSet]: IconResultSetWidgetComponent,
        [WidgetTypes.SearchResults]: ZoomResultWidgetComponent,
        [WidgetTypes.MenuItemShortcut]: MenuItemShortcutWidgetComponent,
        [WidgetTypes.IdentityShortcut]: IdentityShortcutWidgetComponent
        // [WidgetTypes.NameValueList]: ListValueWidgetComponent,
        // [WidgetTypes.Search]: MemoWidgetComponent,
        // [WidgetTypes.NameValueList]: StackedGroupedColumnWidgetComponent,
        // [WidgetTypes.Search]: NumericToBasicLineWidgetComponent,
    };

    async initialize(): Promise<void> {

        this.vm.widgetRef.dragStarted.pipe(untilDestroyed(this)).subscribe(() => {
            this.showColorPicker = false;
            this.cd.detectChanges();
        });

        this.vm.widgetRef.loadingErrorChanged.pipe(untilDestroyed(this)).subscribe((response) => {
            this.errorResponse = response.operationSuccedeed ? null : response
            this.widgetInstance.hasError$.next(!response.operationSuccedeed);
            this.cd.detectChanges();
        });
        this.widgetInstance.forceHideLoader.pipe(untilDestroyed(this)).subscribe(() => {
            this.loading = false;
            this.cd.detectChanges();
        });
        this.widgetInstance.forceShowLoader.pipe(untilDestroyed(this)).subscribe(() => {
            this.loading = true;
            this.cd.detectChanges();
        });
        await this.widgetInstance.initialize();

        this.colorPicker = await this.widgetInstance.getCurrentWidgetColor();
        this.initColorPicker = this.colorPicker;

        this.cd.detectChanges();
    }

    async ngOnInit() {
    }

    removeWidget() {
        this.orchestratorViewModel.rootViewModel.content.removeByIdentity(this.vm.getDomainModel().currentIdentity);
        this.cd.detectChanges();
    }

    errorDetails() {
        this.modalService.showPopUp(this.errorResponse.informations, this.errorResponse.errors);
    }

    updateColorFromColorPicker(event) {
        if (this.widgetInstance.checkIfColorIsValid(event)) {
            this.disableConfirm = false;
            this.widgetInstance.colorChanged.next(event);
        } else {
            this.disableConfirm = true;
        }
        this.cd.detectChanges();
    }

    cancelPickerClick() {
        this.saveColorPicker = false;
        this.showColorPicker = false;
        this.cd.detectChanges();
    }

    async confirmPickerClick() {
        if (!this.disableConfirm) {
            this.saveColorPicker = true;
            this.showColorPicker = false;
            this.cd.detectChanges();
        } else {
            this.saveColorPicker = false;
        }
    }

    onClosingPicker() {
        if (this.saveColorPicker) {
            this.initColorPicker = this.colorPicker;
        } else {
            this.colorPicker = this.initColorPicker;
            this.widgetInstance.colorChanged.next(this.colorPicker)
        }

        this.cd.detectChanges();
        this.saveColorPicker = false;
    }
}
