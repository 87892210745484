import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { WidgetComponentInterface } from './widget-component.interface';
import { WidgetExtViewModel } from '../../view-models/widget.ext-view-model';
import { WidgetApiClient } from '../../api-clients/widget.api-client';
import { UICommandInterface, GenericServiceResponse, ToastMessageService, CommandFactory, ServiceResponse, BaseError } from '@nts/std';
import { DashBoardLayoutOrchestratorViewModel } from '../../view-models/dash-board-layout.orchestrator-view-model';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ChangeDetectorRef, Component } from '@angular/core';
import { WidgetDataArg } from '../../domain-models/dto/widget-data-arg-dto';
import { DashBoardItemViewModel } from '../../view-models/dash-board-item.view-model';
import { LocalstorageHelper, LogService } from '@nts/std/src/lib/utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WidgetBaseValueDto } from '../../domain-models/dto/widget-base-value-dto';



export enum WidgetFeatures {
  Color = "WidgetColor",
  GridState = "WidgetGridState"
}

@UntilDestroy()
@Component({
  template: ''
})
export abstract class WidgetComponent implements WidgetComponentInterface {
  vm: WidgetExtViewModel;
  orchestratorViewModel: DashBoardLayoutOrchestratorViewModel;
  toolbarButtons$ = new BehaviorSubject<Array<UICommandInterface>>([]);
  initialData: any;
  // In alcuni casi potrebbe essere utile forzare l'hide del loader per inizializzare il componente sottostante (grafici)
  forceHideLoader = new Subject<void>();
  forceShowLoader = new Subject<void>();
  fontColor = 'white'; // Utilizzato per il loader
  refreshInProgress$ = new BehaviorSubject<boolean>(false);
  colorEditing = false;
  fullScreenMode = false;
  colorChanged = new Subject<string>();
  lastUpdate: number;
  hasError$ = new BehaviorSubject<boolean>(false);

  // tslint:disable-next-line: variable-name
  private _toastMessageService: ToastMessageService;
  // tslint:disable-next-line: variable-name
  private _cd: ChangeDetectorRef;

  constructor(toastMessageService: ToastMessageService, cd: ChangeDetectorRef) {
    this._toastMessageService = toastMessageService;
    this._cd = cd;
  }

  public async getCurrentWidgetColor(): Promise<string> {
    let color: string = await this.getFeatureForCurrentWidget(WidgetFeatures.Color);
    if (!color) {
      do {
        color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      } while (!this.checkIfColorIsValid(color))

      await this.setFeatureForCurrentWidget(WidgetFeatures.Color, color);
    }
    return color;
  }

  protected getWidgetUniqueKey(): string {
    const currentWidgetUniqueKey = `${this.vm.widgetType.value}_${this.vm.code.value}`;
    return `widget${this.fullScreenMode ? 'Fullscreen' : ''}_${currentWidgetUniqueKey}`;
  }

  protected async setFeatureForCurrentWidget(featureKey: WidgetFeatures, object: any) {
    const widgetUniqueKey = this.getWidgetUniqueKey();
    let obj: Object = await LocalstorageHelper.getStorageItem(widgetUniqueKey);
    if (!obj) {
      obj = {};
    }
    obj[featureKey] = object;
    await LocalstorageHelper.setStorageItem(widgetUniqueKey, obj);
  }

  protected async getFeatureForCurrentWidget(featureKey: WidgetFeatures) {
    const widgetUniqueKey: string = this.getWidgetUniqueKey();
    let obj: Object = await LocalstorageHelper.getStorageItem(widgetUniqueKey);
    if (!obj) {
      obj = {};
    }
    return obj[featureKey];
  }

  protected async updateColor() {
    this.colorEditing = true;
  }

  protected getWidgetDataArg(): WidgetDataArg {
    const widgetDataArg = new WidgetDataArg();
    const dashBoardItemViewModel: DashBoardItemViewModel = (this.vm.parent instanceof DashBoardItemViewModel ? this.vm.parent : this.vm.parent.parent) as DashBoardItemViewModel;
    widgetDataArg.dashBoardWidgetIdentity = dashBoardItemViewModel.getDomainModel().currentIdentity;
    widgetDataArg.identity = this.vm.getDomainModel().currentIdentity;
    return widgetDataArg;
  }

  async initialize(): Promise<void> {

    this.colorChanged.pipe(untilDestroyed(this), debounceTime(500)).subscribe(async (color) => {
      await this.setFeatureForCurrentWidget(WidgetFeatures.Color, color);
      await this.updateColor();
      this._cd.detectChanges();
    });

    this.vm.resizeChanged.pipe(untilDestroyed(this)).subscribe((dashboardItemVm: DashBoardItemViewModel) => {
      this.resizeChanged(dashboardItemVm);
    });

    this.forceHideLoader.pipe(untilDestroyed(this)).subscribe(() => {
      this._cd.detectChanges();
    });

    this.forceShowLoader.pipe(untilDestroyed(this)).subscribe(() => {
      this._cd.detectChanges();

    });

    this.orchestratorViewModel.refreshAllWidgets.pipe(untilDestroyed(this)).subscribe(() => {
      this.refreshData();
    });

    this.initComponent().pipe(
      untilDestroyed(this),
      tap(async () => {
        await this.initToolbar()
      })
    ).subscribe();
  }

  public checkIfColorIsValid(color: string, rgbLevel: number = 610, alphaLevel: number = 0.5): boolean {

    let r = null;
    let g = null;
    let b = null;
    let alpha = null;

    if (color.startsWith("rgba(")) {
      let rgb = color.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
      alpha = Number((rgb && rgb[4] || "").trim());
      r = Number(rgb && rgb[1] || 0);
      g = Number(rgb && rgb[2] || 0);
      b = Number(rgb && rgb[3] || 0);
      //console.log(rgb, alpha)
    } else {
      let rh = "0x" + color.substring(1, 3)
      let gh = "0x" + color.substring(3, 5)
      let bh = "0x" + color.substring(5, 7)
      alpha = 1;

      r = Number(rh);
      g = Number(gh);
      b = Number(bh);
    }

    // console.log(color, rh, gh, bh)
    // console.log(color, r, g, b, r + g + b)
    if ((r + g + b) > rgbLevel || alpha < alphaLevel) {
      // console.log("Non Valido")
      return false;
    }
    return true;
  }

  public initComponent(refreshigData = false): Observable<boolean> {

    return this.loadData(refreshigData).pipe(
      untilDestroyed(this),
      switchMap((res) => {
        // Forzo l'hide del loader così il componente viene visualizzato
        this.forceHideLoader.next();
        if (!res) {
          return of(false);
        }

        return this.initView(res, refreshigData).pipe(tap(() => {
          // Forza il refresh dopo il primo avvio
          if (!refreshigData) {
            setTimeout(() => {
              this.refreshData()
            })
          }
        }));
      })
    )
  }

  protected isVisibleRefreshCommand(): Observable<boolean> {
    return of(true);
  }

  getApiClient(): WidgetApiClient {
    return this.orchestratorViewModel.widgetApiClient;
  }

  protected resizeChanged(vm: DashBoardItemViewModel) {
  }

  protected abstract initView(result: any, refreshigData: boolean): Observable<boolean>;

  protected loadData<DTOResponse extends WidgetBaseValueDto>(refreshigData: boolean): Observable<DTOResponse> {
    const obs = this.getLoadDataObservable<DTOResponse>(refreshigData);
    return this.getData<DTOResponse>(obs, refreshigData);
  }

  protected abstract getLoadDataObservable<DTOResponse extends WidgetBaseValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<DTOResponse>>;

  async initToolbar() {
    const command = CommandFactory.createUICommand(
      (x) => this.refreshData(),
      () => of(true),
      null,
      () => this.isVisibleRefreshCommand()
    );
    command.iconClass = 'refresh';
    command.displayName = '';
    command.tooltip = '';

    this.refreshInProgress$.pipe(untilDestroyed(this)).subscribe((refreshInProgress) => {
      command.iconClass$.next(refreshInProgress ? 'refresh-animated' : 'refresh')
    })

    this.toolbarButtons$.next([...this.toolbarButtons$.value, command]);
  }


  protected async refreshData() {
    if (this.refreshInProgress$.value === false) {
      // Per resettare gli errori devo passare un ServiceResponse
      this.vm.loadingErrorChanged.next(new ServiceResponse());

      this.refreshInProgress$.next(true);

      await firstValueFrom(
        this.initComponent(true).pipe(
          untilDestroyed(this),
          tap(() => {
            this.refreshInProgress$.next(false);
          })
        ), { defaultValue: null }
      )
    }
  }

  public getData<DTOResponse extends WidgetBaseValueDto>(
    obs: Observable<GenericServiceResponse<DTOResponse>>,
    refreshigData: boolean
  ): Observable<DTOResponse> {
    if (this.initialData && !refreshigData) {
      return of(this.initialData);
    }
    return obs.pipe(
      tap((response) => {
        if (!response.operationSuccedeed) {
          this.vm.loadingErrorChanged.next(response);
          LogService.warn('Widget loading failed', response);
        } else {
          this.initialData = response.result;
        }
      }),
      map((response) => response.result),
      catchError((err) => {
        const error = new BaseError();
        error.description = err;
        const response = new ServiceResponse();
        response.errors = [error];
        this.vm.loadingErrorChanged.next(response);
        LogService.warn('Widget loading failed', err);
        return of(null);
      })
    );
  }
}
